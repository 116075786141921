import styled from '@emotion/styled';
import { Box } from '@stenajs-webui/core';
import { ClientRectObject } from '@floating-ui/react-dom-interactions';
import { CSSProperties, FC, memo } from 'react';

export interface SpotlightStyle {
  padding?: number;
  borderRadius?: number;
}

interface SpotlightProps {
  rect: ClientRectObject | undefined;
  style?: SpotlightStyle;
}

export const Spotlight: FC<SpotlightProps> = memo(({ rect, style }) => {
  if (!rect) {
    return null;
  }

  return (
    <SpotlightElement
      background={'gray'}
      position={'fixed'}
      style={getSpotlightStyle(rect, style)}
    />
  );
});

const SpotlightElement = styled(Box)`
  transition: all 0.2s;
`;

function getSpotlightStyle(rect: ClientRectObject, style?: SpotlightStyle): CSSProperties {
  const padding = style?.padding ?? 4;
  const borderRadius = style?.borderRadius ?? 4;

  const width = Math.round(rect.width + padding * 2);
  const height = Math.round(rect.height + padding * 2);
  const left = Math.round(rect.left - padding);
  const top = Math.round(rect.top - padding);

  return {
    top: `${top}px`,
    left: `${left}px`,
    width: `${width}px`,
    height: `${height}px`,
    borderRadius: `${borderRadius}px`,
  };
}
