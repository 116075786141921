import { TextVariant, Txt } from '@stenajs-webui/core';
import { Link } from '@stenajs-webui/elements';
import { ChildNode, Element as ElementNode } from 'domhandler';
import { ElementType, parseDocument } from 'htmlparser2';
import { ReactNode, useMemo } from 'react';

interface RendererProps {
  onPressLink?: (url: string) => void;
}

interface UrgentMessageHTMLRendererProps extends RendererProps {
  html: string;
}

export function UrgentMessageHTMLRenderer({ html, ...props }: UrgentMessageHTMLRendererProps) {
  const doc = useMemo(() => parseDocument(html), [html]);

  return doc.children.map((d, i) => renderNode(d, i, props));
}

function renderNode(node: ChildNode, index: number, props: RendererProps): ReactNode {
  switch (node.type) {
    case ElementType.Text:
      return node.data;

    case ElementType.Tag:
      return renderElement(node, index, props);

    default:
      return null;
  }
}

function getVariantFromTag(tag: string): TextVariant | undefined {
  switch (tag) {
    case 'strong':
    case 'b':
      return 'bold';
    default:
      return undefined;
  }
}

function renderElement(node: ElementNode, index: number, props: RendererProps): ReactNode {
  if (
    node.name === 'p' ||
    node.name === 'span' ||
    node.name === 'strong' ||
    node.name === 'b' ||
    node.name === 'i' ||
    node.name === 'em'
  ) {
    return (
      <Txt
        key={index}
        variant={getVariantFromTag(node.name)}
        style={{
          fontStyle: node.name === 'i' || node.name === 'em' ? 'italic' : undefined,
        }}
      >
        {node.children?.map((d, i) => renderNode(d, i, props))}
      </Txt>
    );
  } else if (node.name === 'a') {
    return (
      <Link
        href={node.attribs.href}
        key={index}
        target={'_blank'}
        onClick={() => props.onPressLink?.(node.attribs.href)}
      >
        {node.children?.map((d, i) => renderNode(d, i, props))}
      </Link>
    );
  } else if (node.name === 'br') {
    return <br />;
  }

  return node.children?.map((d, i) => renderNode(d, i, props));
}
