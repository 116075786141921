import { Column, Row, Space, Txt } from '@stenajs-webui/core';
import { FlatButton, PrimaryButton } from '@stenajs-webui/elements';
import { FC } from 'react';
import { batch, useDispatch } from 'react-redux';
import { UrgentMessageHTMLRenderer } from '../../../common-ui/UrgentMessageHTMLRenderer';
import { markUrgentMessageAsUnread } from '../../../services/notifications/notificationsActions';
import { ModalHeader } from '../../../use-cases/manage/details/components/modal/ModalHeader';
import { UrgentMessage } from '../../graphql/fragments/gql/UrgentMessage';
import { TrackerCategory, trackEvent } from '../../tracking/trackerService';

interface UrgentMessageModalProps {
  onClose: () => void;
  urgentMessage: UrgentMessage;
  trackerCategory: TrackerCategory;
  trackerAction: string;
}

export const UrgentMessageModal: FC<UrgentMessageModalProps> = ({
  onClose,
  urgentMessage: { header, id, routes, textHtml },
  trackerAction,
  trackerCategory,
}) => {
  const dispatch = useDispatch();

  const markAsUnread = () => {
    trackEvent({
      category: trackerCategory,
      action: trackerAction,
      label: 'Remind me button',
    });

    batch(() => {
      dispatch(markUrgentMessageAsUnread(id));
      onClose();
    });
  };

  const onCloseWithTracker = (label: string) => () => {
    trackEvent({
      category: trackerCategory,
      action: trackerAction,
      label,
    });

    onClose();
  };

  return (
    <Column width={500} maxWidth={'100%'}>
      <ModalHeader onRequestClose={onCloseWithTracker('Close message')} headerText={header} />
      <Column indent={3}>
        {routes.length > 0 && (
          <>
            <ul style={{ padding: 0, margin: 0, listStyleType: 'none' }}>
              {routes.map(r => (
                <li key={r.code}>
                  <Txt variant={'overline'} size={'small'}>
                    {r.longDescription}
                  </Txt>
                </li>
              ))}
            </ul>
            <Space num={2} />
          </>
        )}
        <Txt style={{ overflowWrap: 'break-word' }}>
          <UrgentMessageHTMLRenderer
            html={textHtml}
            onPressLink={url => {
              trackEvent({
                category: trackerCategory,
                action: 'Pressed link in urgent message',
                label: url,
              });
            }}
          />
        </Txt>
        <Space num={2} />
        <Row justifyContent={'flex-end'} alignItems={'center'}>
          <FlatButton label={'Remind me'} onClick={markAsUnread} />
          <Space />
          <PrimaryButton
            label={'Ok, got it'}
            onClick={onCloseWithTracker("Don't remind me button")}
          />
        </Row>
        <Space num={3} />
      </Column>
    </Column>
  );
};
